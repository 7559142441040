<template>
  <div v-if="loaderDatas.loaderShow" class="loader">
    <div class="modal">
      <div @click="closeModal" v-if="!loaderDatas.loaderImage" class="close">
        &#x3c;
      </div>
      <div id="loaderMessage">
        {{ loaderDatas.loaderMessage }}
      </div>
      <div v-if="loaderDatas.loaderImage" class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: "loaderModal",
  props: ['loaderData'],
  data() {
    return {
      loaderDatas: this.loaderData
    }
  },
  methods: {
    closeModal() {
      this.loaderDatas.loaderMessage = '';
      this.loaderDatas.loaderShow = false;
    }
  }
}
</script>

<style scoped>
.close {
  font-family: Anton, sans-serif;
  font-size: 20pt;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  left: 10px;
  top: -5px;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000088;
}

.modal {
  width: 40vw;
  text-align: center;
  flex-direction: column;
  height: 50vh;
  display: flex;
  position: relative;
  justify-content: center;
  min-width: 280px;
  min-height: 200px;
  max-height: 300px;
  align-items: center;
  padding: 2% 4%;
  max-width: 450px;
  background-color: #dddddd;
  border-radius: 10px;
}

#loaderMessage {
  margin-top: 30px;
  font-size: 16pt;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
}


</style>