<template>
  <div class="loginModal">
    <div class="logIn" @click="visibilityLogInModal">{{ $t('home.menu1') }}</div>

    <div class="modal" :class="{
      showModal: visibleLoginModal,
      hideRightModal: !visibleLoginModal && !visibleForgetPassModal,
      hideLeftModal: !visibleLoginModal && visibleForgetPassModal
    }">
      <p class="closeArrow" @click="visibilityLogInModal">&#x3c;</p>
      <h3 class="title">{{ $t('login.hello') }}</h3>
      <input type="text" aria-label="Nazwa użytkownika" :placeholder="$t('login.email')" v-model="login">
      <input type="password" aria-label="Hasło" :placeholder="$t('register.pass')" v-model="password">
      <p class="forgotPassword" @click="visiblityForgotPasswordModal">{{ $t('login.getpass') }}</p>
      <p class="logInError">{{ loginError }}</p>
      <button @click="tryToLogIn">{{ $t('login.login') }}</button>
    </div>

    <div class="modal" :class="{ showModal: visibleForgetPassModal, hideRightModal: !visibleForgetPassModal }">
      <p class="closeArrow" @click="visiblityForgotPasswordModal">&#x3c;</p>
      <h3 class="title">{{ $t('login.getpass') }}</h3>
      <p class="forgotPassInfo">
        {{ $t('login.info1') }}
      </p>
      <input type="email" aria-label="adres e-mail" :placeholder="$t('register.email')" v-model="email">
      <p class="forgotError">{{ forgotError }}</p>
      <p>{{ forgotMess }}</p>
      <button v-if="!forgotMess" @click="forgotPassword">{{ $t('login.remind') }}</button>
    </div>
  </div>
</template>

<script>
export default {

  name: 'LoginModal',
  data() {
    return {
      visibleLoginModal: 0,
      visibleForgetPassModal: 0,
      email: '',
      login: '',
      password: '',
      forgotError: '',
      forgotMess: '',
      loginError: '',
      loaderData: { loaderMessage: "", loaderImage: false, loaderShow: false }
    }
  },
  created() {
    this.$emit('loaderDiv', this.loaderData);
  },
  methods: {
    visibilityLogInModal() {
      this.visibleLoginModal = !this.visibleLoginModal
      this.$emit('hideOptions', this.visibleLoginModal);
    },
    visiblityForgotPasswordModal() {
      this.visibleLoginModal = !this.visibleLoginModal
      this.visibleForgetPassModal = !this.visibleForgetPassModal
    },
    tryToLogIn() {

      if (this.login.length < 3 || this.password.length < 3) {
        this.loginError = this.$t("login.error");
        return false;
      }

      this.loaderData.loaderImage = true;
      this.loaderData.loaderShow = true;


      const params = new URLSearchParams();
      params.append("login", this.login.trim());
      params.append("password", this.password.trim());

      const token = localStorage.getItem("token") ? localStorage.getItem("token") : "";
      params.append("token", token);

      this.axios.post("/user-login", params)
        .then((response) => {
          this.loginError = "";

          let res;
          try {
            res = JSON.parse(response.request.response);
            this.loaderData.loaderShow = false;
          } catch (e) {
            this.loaderData.loaderMessage = this.$t("error");
            return false;
          }

          if (res.authorized) {
            localStorage.setItem("user", res.name);
            localStorage.setItem("loginToken", res.token);
            this.$router.push("/gamemenu");

          } else {
            this.loginError = res.message;
          }

        })
        .catch((error) => {
          this.loaderData.loaderMessage = this.$t("error");
          console.log(error);
        }).finally(() => {
          this.loaderData.loaderImage = false;
        });
    },
    forgotPassword() {
      if (this.email.length < 5) {
        this.forgotError = this.$t("login.error1");
        return false;
      }
      this.loaderData.loaderImage = true;
      this.loaderData.loaderShow = true;
      this.forgotError = '';

      const params = new URLSearchParams();
      params.append("email", this.email);
      const self = this;
      this.forgotMess = ' '; //hide send button
      this.axios.post("/recovery-password", params)
        .then((response) => {
          let res;

          try {
            res = JSON.parse(response.request.response);
          } catch (e) {
            self.forgotError = self.$t("error");
            return false;
          }

          if (!res.error) {
            self.forgotMess = self.$t("login.info2");
          } else {
            self.forgotMess = '';
            self.loaderData.loaderShow = false;
            self.forgotError = self.$t("login.error2");
          }
        })
        .catch((error) => {
          self.forgotError = self.$t("error");
          console.log(error);
        }).finally(() => {
          self.loaderData.loaderImage = false;
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* powtórzony kod z app.vue - źle się styl złącza do loginmodal po wylogowaniu*/

@media (max-width: 340px) {
  .title {
    font-size: 1em !important;
  }

}
::placeholder {
  color: #aaa;
}

.modal .title {
  font-weight: lighter;
  margin-top: 30px;
  text-transform: uppercase;
}

input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-family: 'Montserrat', sans-serif;
  background: none;
  border: 0;
  padding: 5px;
  width: 80%;
  margin-top: 25px;
  color: whitesmoke;
  font-size: 0.55em;
  border-bottom: 1px solid whitesmoke;
  transition-duration: .5s;
}

.modal {
  font-family: 'Appareo', sans-serif;
  background-color: #353535dd;
  border-radius: 35px;
  padding: 3% 5%;
  color: whitesmoke;
  position: absolute;
  top: 13%;
  left: 50%;
  overflow: auto;
  max-height: 540px;
  height: 70vh;
  right: 50%;
  cursor: default;
  bottom: 0;
  margin: auto;
  text-align: center;
  transition-duration: 1s;
}

.logIn {
  width: 100%;
}

.showModal {
  left: 105%;
}

.hideRightModal {
  left: 200%;
}

.hideLeftModal {
  left: 0;
}

.closeArrow {
  position: absolute;
  left: 15px;
  top: -20px;
  padding: 10px;
  cursor: pointer;
  font-size: 20pt;
  color: whitesmoke;
}

.forgotPassword {
  font-family: 'Montserrat', sans-serif;
  color: whitesmoke;
  font-size: 11pt;
  font-weight: bold;
  margin-top: 20px;
  width: 100%;
}

input[type="password"] {
  margin-top: 35px !important;
}

input[type="email"] {
  margin-top: 35px !important;
}

button {
  font-family: 'Appareo', sans-serif;
  border-radius: 25px;
  background-color: #c32e34;
  color: whitesmoke;
  font-size: 0.97em;
  padding: 20px 10px;
  width: 94%;
  box-shadow: 0 0 20px 1px #111;
  margin-top: 55px;
  border: 0;

}

.forgotPassInfo {
  font-family: 'Montserrat', sans-serif;
  font-size: 12pt;
  width: 260px;
  margin: 25px auto;
}

.forgotError,
.logInError {
  color: #c32e34;
  font-family: 'Montserrat', sans-serif;
  font-size: 10pt;
  margin: 10px 0 0 0;
}
</style>
