//import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

//Vue.use(Router)

const routes = [
    {
        path: '/',
        alias: '/show',
        name: 'Home',
        component: Home
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/gamemenu',
        name: 'GameMenu',
        component: () => import('../views/GameMenu.vue')
    },
    {
        path: '/waitforstart',
        name: 'waitForStartGame',
        component: () => import('../views/waitForStartGame.vue')
    },
    {
        path: '/game',
        name: 'Game',
        component: () => import('../views/Game.vue')
    },
    {
        path: '/results',
        name: 'endGame',
        component: () => import('../views/endGame.vue')
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import('../views/Payment.vue')
    }
]

const router = new createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router
