<template>
  <div class="home">
    <img v-if="!customLogo" alt="Logo" class="logo" src="../assets/logo.png">
    <img v-if="customLogo" alt="Logo" class="custom logo" :src="customLogo">
    <div v-if="!customLogo" class="social-flex">
      <a target="_blank" href="https://www.facebook.com/PubCrime">
        <img src="../assets/fb.png" alt="FB" />
      </a>
      <a target="_blank" href="https://www.instagram.com/pubcrime">
        <img src="../assets/ig.png" alt="IG" />
      </a>
    </div>
    <div class="lang">
      <div v-for="locale in locales" :key="locale">
        <img :alt="locale" :src="getFlag(locale)" @click="switchLocale(locale)">
      </div>
    </div>
    <div class="optionsContainer" :class="{ hiddenOptions: !visibilityItems }">

      <LoginModal class="option" @loaderDiv="setLoaderData" @hideOptions="visibilityItems = !visibilityItems" />

      <div class="option" @click="playWithOutLoggin">{{ $t('home.menu2') }}</div>
      <router-link class="option" to="/register">{{ $t('home.menu3') }}</router-link>

      <a style="margin-bottom: 10px;" class="option" href="https://pubcrime.pl/sklep" target="_blank">
        KUP BILET
      </a>

      <small class="small">
        LUB OPŁAĆ WPISOWE U PROWADZĄCEGO
      </small>
    </div>

    <loader-modal :loaderData="loaderData" />
    <fcm-init />
  </div>
</template>

<script>
// @ is an alias to /src
import LoginModal from '@/components/LoginModal.vue'
import loaderModal from '@/components/loaderModal.vue'
import FcmInit from "../components/fcmInit";

export default {

  name: 'Home',
  components: {
    FcmInit,
    LoginModal,
    loaderModal
  },
  data() {
    return {
      visibilityItems: 1,
      customLogo: localStorage.getItem("customLogo"),
      loaderData: { loaderMessage: "", loaderImage: false, loaderShow: false },
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
    }
  },
  created() {
    if (this.$route.query.passwordChanged) {
      this.loaderData.loaderShow = true;
      this.loaderData.loaderMessage = "Hasło zostało zmienione, zaloguj się używając nowego hasła.";
    }
    localStorage.removeItem("closeHint");

    if (!this.customLogo) {
      this.checkNewLogo();
      let callCount = 1;
      const self = this;
      let repeater = setInterval(function () {
        if (callCount < 8 && !this.customLogo) {
          self.checkNewLogo();
          callCount += 1;
        } else {
          clearInterval(repeater);
        }
      }, 500);
    }
  },
  methods: {
    checkNewLogo() {
      this.customLogo = localStorage.getItem("customLogo");
    },
    switchLocale(locale) {
      localStorage.setItem("locale", locale);
      this.$i18n.locale = locale;
    },
    getFlag(country) {
      return require('../assets/' + country + '.png');
    },
    setLoaderData(data) {
      this.loaderData = data;
    },
    playWithOutLoggin() {
      this.loaderData.loaderImage = true;
      this.loaderData.loaderShow = true;

      const params = new URLSearchParams();
      params.append("token", localStorage.getItem("token") ? localStorage.getItem("token") : "");

      this.axios.post("/play-without-login", params)
        .then((response) => {
          this.loaderData.loaderImage = false;
          let res;
          try {
            res = JSON.parse(response.request.response);
          } catch (e) {
            this.loaderData.loaderMessage = "Wystąpił błąd, spróbuj ponownie";
            return false;
          }

          localStorage.setItem("user", res.user);
          localStorage.setItem("loginToken", res.loginToken);
          localStorage.setItem("withoutlog", '1');

          this.$router.push('/gamemenu').catch(() => { });

        }).catch((error) => {
          this.loaderData.loaderImage = false;
          this.loaderData.loaderMessage = "Wystąpił błąd, spróbuj ponownie";
          console.log(error);
        });
    }
  }
}
</script>

<style scoped>
.small {
  display: block;
  margin: auto;
  color: whitesmoke;
}

.home {
  position: relative;
  overflow: hidden;
  font-family: 'Appareo' !important;
  background-image: url("../assets/tlopubcrime.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.recoveryGame {
  cursor: pointer;
  border: 1px solid #333;
  position: absolute;
  left: 3%;
  top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  font-weight: bold;
  height: 20px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 100%;
}

.optionsContainer {
  padding-top: 20%;
  width: 100%;
  margin-left: 1px;
  position: relative;
  transition-duration: 1s;
}

.hiddenOptions {
  margin-left: -100%;
}

.option {
  width: 70%;
  max-width: 300px;
  margin: 50px auto;
  background-color: #333;
  display: block;
  color: whitesmoke;
  border-radius: 25px;
  font-size: 1.5em;
  padding: 20px 10px;
  text-decoration: none;
  cursor: pointer;
}

.option:first-child {
  color: whitesmoke;
  font-size: 2em !important;
  background-color: #c32e34;
  margin-top: 0;
}

.option:last-child {
  margin-bottom: 0;
}

.custom.logo {
  left: 10px !important;
  top: 5px !important;
  max-width: 90vw;
  height: 100px !important;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 16vh;
  margin: auto;
}

.social-flex {
  display: flex;
  position: absolute;
  right: 80px;
  z-index: 1;
  top: 10px;
  flex-direction: column;
  justify-content: space-around;
}

.social-flex img {
  width: 35px !important;
  border-radius: 16px;
}

.lang {
  position: absolute;
  top: 0;
  right: 2%;
  font-size: 2em;
}

.lang div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
  height: 30px;
}

.lang img {
  width: 40px;
  border-radius: 2px;
  border: 1px solid #333;
}
</style>
