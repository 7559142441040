//import Vue from 'vue'
import { createI18n } from 'vue-i18n'
import jsonEN from './locales/en.json'
import jsonPL from './locales/pl.json'

//Vue.use(VueI18n)

// require context przestało działać po aktualizacji
// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   console.log(jsonen);
//   console.log(locales.keys().length);
//   locales.keys().forEach(key => {
//
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

const messages = {
  en: jsonEN,
  pl: jsonPL
}

export default new createI18n({
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'pl',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pl',
  messages,
  silentFallbackWarn: true
})
