export default {
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occurred. Please try again"])},
  "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refreshing..."])},
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACK"])},
  "home": {
    "recovery1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the game and you are unable to return to your team?"])},
    "recovery2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address and you will receive a link to an active game on your account."])},
    "recoveryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send e-mail"])},
    "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link to the game has been sent to the e-mail address provided"])},
    "menu1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOG IN"])},
    "menu2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play without logging in"])},
    "menu3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
  },
  "rank": {
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team name"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of agents"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single"])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quit the investigation"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "game": {
    "tab1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials"])},
    "tab2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers"])},
    "endround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of round "])},
    "showResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESULTS"])},
    "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit solution"])},
    "sendTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of sending the reply "])},
    "endInvest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The investigation has ended"])},
    "emptyAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No answer was chosen"])},
    "sentAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replies sent"])},
    "startHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILES"])},
    "endHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOLVING THE CASE"])},
    "showRes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESULTS"])},
    "becameLeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been elected as the new team leader."])},
    "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "keyTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEY TIP"])},
    "sendInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The answers will be sent after clicking the button"])}
  },
  "fcm": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow notifications in the browser settings"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is necessary to accept notifications to play properly!"])}
  },
  "multiplayer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of game"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-person"])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple"])}
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exiting a game"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave the investigation?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "wait": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged in, wait for the game to start"])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock access to the scenario!"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can pay online or with Crime Master"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access granted"])},
    "noaccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access, settle the payment"])},
    "begin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START AN INVESTIGATION"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online payment"])}
  },
  "teambar": {
    "capitan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captain"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(one-person)"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player accepted"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player rejected"])},
    "kickPlayer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Player ", _interpolate(_named("name")), " was kicked"])},
    "kickMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to kick ", _interpolate(_named("name")), " from your team?"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your team"])},
    "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "teamname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team name"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
    "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirence"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been kicked off from the team"])}
  },
  "gamerules": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GAME RULES"])},
    "rule1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal of the game"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["… is a game in which your main aim is to solve a crime. Players in each of the participating teams take on the role of investigators of a detective agency. Being a detective is all about intuition, perceptiveness and deduction. Check if you have what it takes to succeed!"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The participants' task is to pick and choose a correct variant from among 6 in each of the crime components. You need three accurate variants to solve the puzzle."])},
    "rule2title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to play?"])},
    "rule21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• An unlimited number of teams can take part in the game. There is also no limit to number of people in each of the teams."])},
    "rule22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Before the first round, the Crime Master will present various evidence related to the crime – for example suspects’ descriptions, excerpts from interrogations etc."])},
    "rule23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• The game consists of 3 rounds. During each of them, the evidence found at the crime scene will be presented. Sometimes it is an object, sometimes a trace, sometimes an overheard conversation."])},
    "rule24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• In the first round, 9 clues will be presented (3 for each crime component)"])},
    "rule25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• In the second round, the Crime Master will give participants 6 hints (two for each of the components), and in the last round only 3 (one for each component)."])},
    "rule26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Only one hint per round is \"important to the case\". The other (two in the first round and one in the second) are irrelevant. In the final round all hints are relevant! Players’ task is to separate relevant clues from the irrelevant ones and use them to deduce correct variants in each of the 3 components of the crime."])},
    "rule3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to answer?"])},
    "rule31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• At the beginning of the game, the team should choose its Captain."])},
    "rule32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• We use the app to send the team’s answer to the Crime Master. The captain, after consulting the other team members, marks one variant in each of the components of the crime – ‘Perpetrator’, ‘Method’ and ‘Motive’."])},
    "rule33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• The team that sends their answer first, no matter right or wrong, will be displayed at the top of the team ranking."])},
    "rule34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• At any time (and any number of times), the Captain may change his team's responses."])},
    "rule35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• After the Captain changes the responses, the team moves to the end of the team ranking."])},
    "rule4title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who will be the winner?"])},
    "rule4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The team which answers are going to be closest to the correct solution and is the highest in the team ranking will be the winner of the game."])},
    "rule5title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to win?"])},
    "rule51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Take notes! Take lots of notes! Save the details! You can use a mobile phone for this purpose."])},
    "rule52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Remember that you can change your mind at any time. It is more important to give the correct answer than to be the fastest."])},
    "rule53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Presented photos are for reference only. Listen carefully to what the Crime Master says!"])},
    "rule54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Remember that each of the three groups of clues relates to one component."])},
    "rule55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Talk to other party members! Exchange concepts and ideas!"])}
  },
  "login": {
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HELLO DETECTIVE!"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "getpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password recovery"])},
    "info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the e-mail address on which your account was created"])},
    "remind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remind password"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your login and password"])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your e-mail address"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no account with the given e-mail address"])},
    "info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further instructions have been sent to the e-mail address provided"])}
  },
  "jointeam": {
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "teamname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team name"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the team you want to join"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join a detective agency"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no agency matching your criteria"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report sent"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name of a venue or team’s name"])}
  },
  "createteam": {
    "gamecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game code"])},
    "info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the 4-digit code received from the Crime Master (the host)"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve a name"])},
    "info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating an account allows you to reserve a team name!"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a team"])},
    "info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code you received by e-mail after purchasing the ticket"])},
    "scenariocode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scenario code"])},
    "yourteams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reserved teams"])},
    "emptyteams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reserved team names"])},
    "info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account to reserve a team"])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the team should be at least 4 characters long"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The team name should be 23 characters or less"])},
    "error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid game code"])},
    "error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid e-mail address"])},
    "error5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong scenario access code"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "giveemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email address is required to receive information about the won prizes."])}
  },
  "gamemenu": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hello, ", _interpolate(_named("username"))])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a team"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the"])},
    "join1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to menu"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to menu"])},
    "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game rules"])}
  },
  "register": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating an account allows you to reserve a team name!"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address*"])},
    "email_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The e-mail address provided is already in use"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "username_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specified username is already in use"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The city you are playing in?*"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "repeat_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password*"])},
    "consent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I consent to the processing of my personal data in accordance with the"])},
    "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
    "consent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I consent to the processing by New Entertainment Tomasz Pająkowski, ul. Beskidzka 1/49 in Bydgoszcz, my personal data in the form of an e-mail address for the purpose of sending me marketing information regarding products and services offered by New Entertainment, by means of electronic communication, in accordance with the provisions of art. 10 sec. 1 and 2 on the provision of electronic services."])},
    "correctError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct the following errors:"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
  }
}