// eslint-disable-next-line
import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import i18n from './i18n'
import VueGtag from 'vue-gtag';
import { useRoute, useRouter } from 'vue-router'
import * as Sentry from "@sentry/vue";

//Vue.config.productionTip = false
//Vue.config.silent = true //silent all warnings

// Configuration VueAnalytics
// Vue.use(VueAnalytics, {
//     id: 'UA-117079234-3',
//     router
// });

const app = createApp({
    methods: {
        security() {
            //if logged in or not logged in
            if (localStorage.getItem('loginToken')) {
                const params = new URLSearchParams();
                let reload = false;
                const config = { headers: { Authorization: `Bearer ${localStorage.getItem("loginToken")}` } };
                this.axios.post('/check-authorization', params, config)
                    .then((response) => {
                        let res;
                        try {
                            res = JSON.parse(response.request.response);
                        } catch (e) {
                            console.error(e);
                            reload = confirm('Wystąpił błąd, kliknij ok aby odświeżyć stronę');
                        }

                        window.gameData = res.game;
                        if (res.game && res.game.perp) {
                            window.gameData.perperator = res.game.perp;
                        }

                        if (res.authorized) {
                            if (res.game && localStorage.getItem("state") !== null) {
                                if ((res.game.stan === 0 || localStorage.getItem("state") === "0")) {
                                    if (this.$route.path !== '/waitforstart')
                                        this.$router.push('/waitforstart');
                                } else if (res.game.stan === 1 && this.$route.path !== '/game') {
                                    this.$router.push('/game');
                                } else if (res.game.stan >= 2 && this.$route.path !== '/game' && this.$route.path !== '/results') {
                                    this.$router.push('/game');
                                }
                            } else if ((this.$route.path !== '/gamemenu' && this.$route.path !== "/gamerules")) {
                                this.$router.push('/gamemenu');
                            }
                        } else {
                            if (this.$route.name !== 'Home' && this.$route.path !== "/register")
                                this.$router.push('/');
                        }

                    }).catch((error) => {
                        console.error(error);
                        if (this.$route.name !== 'Home')
                            this.$router.push('/');
                    })
                    .finally(() => {
                        if (reload)
                            location.reload();
                    });

            } else if (this.$route.name !== 'Home' && this.$route.path !== "/register") {
                const token = localStorage.getItem("token");
                localStorage.clear();
                localStorage.setItem("token", token);
                this.$router.push('/');
            }
        },
        getCustomLogo() {
            localStorage.removeItem('customLogo');
            const domain = window.location.hostname;

            if(domain != 'game.pubcrime.pl' && domain != 'localhost') { // != default
                this.axios.get('/pc-company-icon?company=' + domain)
                    .then((response) => {
                        let res;
                        try {
                            res = JSON.parse(response.request.response);
                        } catch (e) {
                            console.error(e);
                            return false;
                        }
                        
                        if(res.icon.length > 1) {
                            let serv = this.axios.defaults.baseURL;
                            serv = serv.replace('api', '');
                            const fullPath =  serv + 'images/company-logos/' + res.icon;
                            localStorage.setItem('customLogo', fullPath);
                        } else {
                            window.location.href = 'https://game.pubcrime.pl/';
                        }
                    });
            }
        }
    },
    async mounted() {
        this.$i18n.locale = localStorage.getItem("locale");
        const self = this;
        const route = useRoute();
        const router = useRouter();
        await router.isReady();
        this.getCustomLogo();

        if (route.path !== "/payment") {
            self.security();
        }

    },
    render: () => (
        h(App)
    )
});

Sentry.init({
    app,
    dsn: "https://508cb0557d533e7c4112183f802a8a3f@o4506185736060928.ingest.sentry.io/4506185934569472",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/game\.pubcrime\.pl/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


app.use(VueGtag, {
    appName: 'Pubcrime App',
    pageTrackerScreenviewEnabled: true,
    config: { id: 'UA-117079234-3' }
});
app.use(i18n);
app.use(router);
app.mount('#app');
//global axios & api url
const base = axios.create({
    headers: {
        'Accept': 'application/json',
    },
    //baseURL: "http://127.0.0.1:84/api"
    baseURL: "https://panel.pubquiz.pl/api"
});

app.config.globalProperties.axios = { ...base }

//Vue.prototype.axios = base;

//localstorage key
// user, userid, teamID, gameID, state, teamMaster,
// withoutlog, checksum, tokenError, roundNotification
// gameType (single/multi)


//new localStorage
//user, customLogo, token, loginToken, withoutlog, state, roundNotification