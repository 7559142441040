<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {

  name: 'app',
  created() {
    document.title = "Pubcrime - game";
  }
}
</script>

<style>
@font-face {
  font-family: 'Appareo';
  src: url('assets/Appareo_Light.ttf');
}

.modal{
  background-color: #353535f5;
  overflow: auto;
  position: absolute;
  left: 5vw;
  box-sizing: border-box;
  right: 5vw;
  top: 15vh;
  margin: auto;
  bottom: 2.5vh;
  width: 90vw;
  border-radius: 35px;
  text-align: center;
  transition-duration: .8s;
  padding: 15px;
}

.message {
  padding: 15px;
}

.logo {
  position: absolute;
  top: -2vh;
  left: 0;
  height: 17vh;
  margin: auto;
}

body {
  margin: 0;
}

input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-family: 'Montserrat', sans-serif;
  background: none;
  border: 0;
  padding: 5px;
  border-bottom: 2px solid black;
  transition-duration: .5s;
}

input:focus {
  outline: none;
  box-shadow: 0 6px 8px -4px white;
}

input::placeholder {
  color: #333;
}

/*loader image css*/
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
