export default {
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił błąd, spróbuj ponownie"])},
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponów próbę"])},
  "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odświeżanie..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POWRÓT"])},
  "home": {
    "recovery1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W trakcie gry wystąpił błąd i nie możesz powrócić do swojej drużyny? "])},
    "recovery2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj adres e-mail, a otrzymasz link do aktywnej gry na Twoim koncie."])},
    "recoveryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij e-mail"])},
    "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do gry został wysłany na podany adres e-mail"])},
    "menu1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZALOGUJ"])},
    "menu2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zagraj bez logowania"])},
    "menu3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejestracja"])}
  },
  "rank": {
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa drużyny"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba \n agentów"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedzi"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednoosobowe"])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wieloosobowe"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjdź ze śledztwa"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót"])}
  },
  "game": {
    "tab1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiały"])},
    "tab2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedzi"])},
    "endround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec rundy "])},
    "showResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WYNIKI"])},
    "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij rozwiązanie"])},
    "sendTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas wysłania odpowiedzi "])},
    "endInvest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Śledztwo zostało zakończone"])},
    "emptyAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie została wybrana żadna odpowiedź"])},
    "sentAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłano odpowiedzi"])},
    "startHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AKTA"])},
    "endHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROZWIĄZANIE SPRAWY"])},
    "showRes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WYNIKI"])},
    "becameLeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zosałeś wybrany/a nowym kapitanem drużyny."])},
    "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiek"])},
    "keyTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KLUCZOWA WSKAZÓWKA"])},
    "sendInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedź zostanie przesłana po kliknięciu przycisku"])}
  },
  "fcm": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól na powiadomienia w ustawieniach przeglądarki"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do prawidłowej gry konieczna jest akceptacja powiadomień!"])}
  },
  "multiplayer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz rodzaj rozgrywki"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednoosobowa"])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wieloosobowa"])}
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opuszczanie rozgrywki"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz zrezygnować ze śledztwa?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])}
  },
  "wait": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jesteś zalogowany, poczekaj na start gry"])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odblokuj dostęp do scenariusza!"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszt"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności możesz dokonać online lub u prowadzącego"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp przyznany"])},
    "noaccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak dostępu, ureguluj płatność"])},
    "begin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROZPOCZNIJ ŚLEDZTWO"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatność online"])}
  },
  "teambar": {
    "capitan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitan"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(jednoosobowa)"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyjęto gracza"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzucono gracza"])},
    "kickPlayer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gracz ", _interpolate(_named("name")), " został wyrzucony"])},
    "kickMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Czy na pewno chcesz wyrzucić z drużyny gracza ", _interpolate(_named("name")), "?"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół"])},
    "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcja"])},
    "teamname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa drużyny"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa użytkownika"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mój profil"])},
    "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doświadczenie"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostałeś wyrzucony z drużyny"])}
  },
  "gamerules": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZASADY GRY"])},
    "rule1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel gry"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["… to gra, w której podobnie jak w escape roomie należy rozwiązać zagadkę. Gracze każdej z drużyn wcielą się w rolę pracowników młodej agencji detektywistycznej. W pracy detektywa najbardziej liczy się intuicja, spostrzegawczość i dedukcja. Sprawdźcie, czy macie jej wystarczająco wiele!"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadaniem uczestników jest jak najszybsze wytypowanie elementu zbrodni spośród 6 wariantów. Do rozwiązania zagadki potrzeba trzech trafnych elementów."])},
    "rule2title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak gramy?"])},
    "rule21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• W grze może wziąć udział nieograniczona liczba drużyn. Liczba osób w drużynie jest dowolna."])},
    "rule22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Przed pierwszą rundą prowadzący zaprezentuje różne materiały związane z przestępstwem, którego dotyczyć będzie gra (np. opisy podejrzanych, fragmenty przesłuchań itp.)."])},
    "rule23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Gra ma 3 rundy. W trakcie każdej z nich zostaną zaprezentowane dowody, znalezione na miejscu zbrodni. Czasem jest to przedmiot, czasem ślad, czasem zasłyszana rozmowa."])},
    "rule24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• W pierwszej rundzie zostanie zaprezentowanych 9 wskazówek (po 3 dla każdego elementu zbrodni)."])},
    "rule25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• W drugiej rundzie Prowadzący poda 6 podpowiedzi (po dwie na element), a w ostatniej tylko 3 (po jednej na element)."])},
    "rule26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Tylko jedna podpowiedź na rundę jest „ważna dla sprawy”. Pozostałe (dwie w rundzie pierwszej i jedna w drugiej) są nieistotne. Wszystkie podpowiedzi w ostatniej rundzie są ważne! Zadaniem graczy jest oddzielenie istotnych podpowiedzi od nieistotnych i wydedukowanie na ich podstawie trzech prawidłowych elementów zbrodni."])},
    "rule3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak odpowiadać?"])},
    "rule31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Na początku rozgrywki drużyna powinna wybrać swojego Kapitana."])},
    "rule32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Do zgłoszenia odpowiedzi używamy aplikacji. Kapitan po konsultacji z pozostałymi członkami drużyny zaznacza w niej Sprawcę, Metodę i Motyw."])},
    "rule33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Pierwsza wysłana odpowiedź znajdzie się na początku kolejki."])},
    "rule34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• W każdej chwili (i dowolną liczbę razy) Kapitan może zmienić odpowiedzi swojej drużyny."])},
    "rule35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Po zmianie odpowiedzi przez Kapitana, trafiają one na koniec kolejki."])},
    "rule4title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kto wygrywa?"])},
    "rule4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W grze zwycięży drużyna, której odpowiedzi są najbardziej zbliżone do prawidłowego rozwiązania oraz stoją najwyżej w kolejce."])},
    "rule5title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak wygrać?"])},
    "rule51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Rób notatki! Rób dużo notatek! Zapisuj szczegóły! Możesz w tym celu korzystać z telefonu komórkowego."])},
    "rule52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Pamiętaj, że w każdej chwili możesz zmienić zdanie. Ważniejsze jest, by podać prawidłową odpowiedź, niż by być najszybszym."])},
    "rule53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Ilustracje mają charakter poglądowy. Słuchaj uważnie, co dokładnie mówi Prowadzący!"])},
    "rule54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Pamiętaj, że każda z trzech grup wskazówek łączy się z jednym elementem."])},
    "rule55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Rozmawiaj z pozostałymi członkami drużyny! Wymieniajcie się koncepcjami i pomysłami!"])}
  },
  "login": {
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WITAJ DETEKTYWIE!"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail"])},
    "getpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odzyskiwanie hasła"])},
    "info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj adres e-mail na którym zostało założone Twoje konto"])},
    "remind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypomnij hasło"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj login i hasło"])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj adres e-mail"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konta o podanym adresie e-mail"])},
    "info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na podany adres e-mail zostały wysłane dalsze instrukcje"])}
  },
  "jointeam": {
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokal"])},
    "teamname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa drużyny"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WYSZUKAJ"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij na drużynę, do której chcesz dołączyć"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz do agencji detektywistycznej"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak agencji spełniającej podane kryteria"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłano zgłoszenie"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj nazwę lokalu lub drużyny"])}
  },
  "createteam": {
    "gamecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod gry"])},
    "info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz 4-cyfrowy kod, który wyświetlany jest przez prowadzącego na ekranie"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarezerwuj nazwę"])},
    "info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Założenie konta umożliwia rezerwację nazwy drużyny!"])},
    "info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj kod który otrzymałeś na adres e-mail po zakupie biletu"])},
    "scenariocode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod do scenariusza"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stwórz drużynę"])},
    "yourteams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje zarezerwowane drużyny"])},
    "emptyteams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zarezerwowanych drużyn"])},
    "info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załóż konto aby zarezerwować drużynę"])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa drużyny powinna mieć conajmniej 4 znaki"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa drużyny powinna mieć maksymalnie 23 znaki"])},
    "error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy kod gry"])},
    "error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj prawidłowy adres e-mail"])},
    "error5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błędny kod dostępu do scenariusza"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail"])},
    "giveemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail jest potrzebny do ewentualnego kontaktu w sprawie wygranych nagród."])}
  },
  "gamemenu": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Witaj, ", _interpolate(_named("username"))])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stwórz drużynę"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz do"])},
    "join1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drużyny"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do menu"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do menu"])},
    "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasady gry"])}
  },
  "register": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejestracja"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Założenie konta umożliwia rezerwację nazwy drużyny!"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa użytkownika"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail*"])},
    "email_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podany adres e-mail jest już w użyciu"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mężczyzna"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kobieta"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
    "username_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podana nazwa jest już w użyciu"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W jakim mieście grasz?*"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
    "repeat_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz hasło*"])},
    "consent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z"])},
    "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polityką prywatności"])},
    "consent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrażam zgodę na przetwarzanie przez New Entertainment Tomasz Pająkowski, ul. Beskidzka 1/49 w Bydgoszczy, moich danych osobowych w postaci adresu poczty elektronicznej w celu przesyłania mi informacji marketingowych dotyczących produktów i usług oferowanych przez New Entertainment, za pomocą środków komunikacji elektronicznej, tosownie do treści przepisu art. 10 ust. 1 i 2 o świadczeniu usług drogą elektroniczną."])},
    "correctError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popraw następujące błędy:"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj"])}
  }
}