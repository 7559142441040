<template>
  <div v-if="enabledFooter && false" class="footerMessage">
    <div class="closeFooter" @click="disableHint">x</div>
    {{ message }}
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';

export default {

  name: "fcmInit",
  data() {
    return {
      message: this.$t('fcm.message'),
      visible: 1,
      enabledFooter: localStorage.getItem("closeHint") === "1" ? 0 : 1
    }
  },
  created() {
    const config = {
      apiKey: "AIzaSyDqwWn6G4gh8CCWajd92hicDYYPRAastNo",
      authDomain: "pubcrime.firebaseapp.com",
      databaseURL: "https://pubcrime.firebaseio.com",
      projectId: "pubcrime",
      storageBucket: "pubcrime.appspot.com",
      messagingSenderId: "429152391075",
      appId: "1:429152391075:web:509b700d87727404839416"
    };

    if (!firebase.messaging.isSupported())
      return false

    if (firebase.apps.length === 0) {
      firebase.initializeApp(config);
      if (navigator.serviceWorker) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then((registration) => {
              messaging.useServiceWorker(registration)
            }).catch(err => {
          console.log(err);
        });
      }
    }

    const messaging = firebase.messaging();

    const self = this;
    const timeNow = new Date().getTime();

    messaging.requestPermission()
        .then(() => messaging.getToken())
        .then((token) => {

          if ((token !== localStorage.getItem("token") || localStorage.getItem("tokenError") === "true")
              && localStorage.getItem("loginToken")) {
            self.setToken(token);
          }

          localStorage.setItem("token", token);

          this.message = "OK";
          const container = document.getElementsByClassName("footerMessage")[0];
          container.style.backgroundColor = "#46cb1899";
          container.style.color = "black";

          const timeAgree = new Date().getTime();

          if (timeNow > timeAgree - 600) {
            container.style.display = "none";
          }
          setTimeout(function () {
            container.style.bottom = "-100px";
          }, 4500);
        })
        .catch(() => {
          const container = document.getElementsByClassName("footerMessage")[0];
          if (container) {
            container.style.backgroundColor = "#c32e3499";
            container.style.color = "white";
          }
          localStorage.setItem("tokenError", "true");
          if(typeof this.$t !== 'function'){
            return false; //is double created when go to e.g. /game and first time fail
          }
          this.message = this.$t('fcm.access');
        });

    messaging.onMessage(function (payload) {
      if (!self.visible) {
        const options = {
          body: payload.message
          //icon: "../assets/logoround.png"
        }

        // eslint-disable-next-line no-unused-vars
        const n = new Notification(payload.title, options);
      }

      //przyjecie gracza
      if (payload.data.acceptmember) {
        localStorage.setItem("state", "0");
        localStorage.setItem("roundNotification", "0");
        self.$router.push("/waitforstart");
      }

      if (payload.data.newleader) {
        self.gameData.leader = 1;
      }

      if (payload.data.endgame) {
        localStorage.setItem("state", "2");
      }
      //console.log(payload);
      self.$emit("onMessage", payload.data);
    });

    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        self.visible = 1;
      } else {
        self.visible = 0;
      }
    });
  },
  methods: {
    disableHint() {
      this.enabledFooter = 0;
      localStorage.setItem("closeHint", "1");
    },
    setToken(token) {
      const params = new URLSearchParams();
      params.append("token", token);
      const config = {headers: {Authorization: `Bearer ${localStorage.getItem("loginToken")}`}};

      this.axios.post("/set-fcm-token", params, config).then(() => {
            localStorage.setItem("tokenError", "false");
          }).catch(() => {
        localStorage.setItem("tokenError", "true");
      });
    }
  }
}
</script>

<style scoped>
.footerMessage {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  position: absolute;
  left: 0;
  right: 0;
  font-family: 'Montserrat', sans-serif;
  bottom: 0;
  transition-duration: .8s;
  display: flex;
  font-size: 0.9em;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: black;
  background-color: #ffffff99;
  z-index: 5;
}

.closeFooter {
  padding: 5px;
  position: absolute;
  right: 5px;
  top: -3px;
  margin-bottom: 23px;
  font-weight: bold;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
}
</style>